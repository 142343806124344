
import Vue from "vue";

export default Vue.extend({
  name: "Footer",
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
});
