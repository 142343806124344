<template>
  <v-main class="main">
    <loader :loading="$auth.loading || appLoading" />
    <base-raw-header :showLanguageSwitcher="false" />
    <v-container class="main-container">
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
    <RawFooter />
    <snackbars-container />
  </v-main>
</template>

<script>
import BaseRawHeader from "@/components/base/RawHeader.vue";
import Loader from "@/components/base/Loader.vue";
import SnackbarsContainer from "@/components/snackbar/SnackbarsContainer.vue";
import RawFooter from "@/components/v2/base/RawFooter.vue";

export default {
  name: "Default",
  components: {
    SnackbarsContainer,
    BaseRawHeader,
    Loader,
    RawFooter,
  },
  props: {
    appLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.main {
  background-color: #fffaf5;
}

.main-container {
  min-height: calc(100vh - 56px - 100px); // vh - header - footer with topmargin
  padding: 12px 24px;

  @media all and (min-width: 960px) {
    min-height: calc(100vh - 56px - 84px); // vh - header - footer with topmargin
  }
}
</style>
