
import Vue from "vue";

import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "RawHeader",
  components: { Btn },
  methods: {
    logout(): void {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
});
