
import Vue from "vue";

import { Snackbar, SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "SnackbarsContainer",

  computed: {
    snackbars(): Snackbar[] {
      return this.$store.getters["snackbar/getList"];
    },
  },
  methods: {
    close(snackbar: Snackbar) {
      this.$store.dispatch("snackbar/close", snackbar);
    },
    getSnackbarColor(snackbar: Snackbar) {
      switch (snackbar.config.type) {
        case SnackbarType.SUCCESS:
          return "#32A071";
        case SnackbarType.ERROR:
          return "#FF3A44";
        case SnackbarType.WARN:
          return "orange";
        case SnackbarType.INFO:
          return "blue";
        default:
          return "grey";
      }
    },
    getTitle({ config }: Snackbar) {
      if (config.title || config.title === "") {
        return config.title;
      }
      switch (config.type) {
        case SnackbarType.SUCCESS:
          return "Sukces";
        case SnackbarType.ERROR:
          return "Wystąpił błąd";
        case SnackbarType.WARN:
          return "Uważaj";
        case SnackbarType.INFO:
          return "Ważna informacja";
        default:
      }
    },
  },
});
